import React, { PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
type ThemeProviderProps = {
    children: React.ReactNode;
    
};
function LanguageProvider({children ,locale}:PropsWithChildren<{locale:string}>) {
    const {i18n}=useTranslation();
    useEffect(()=>{
        i18n.changeLanguage(locale)
    },[locale])
  return (
    <div dir={locale==="ar"?"rtl":"ltr"}>{children}</div>
  )
}

export default LanguageProvider