import "./bootstrap";
import "../css/app.css";
import "./i18n";

import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ThemeProvider } from "./Components/providers/ThemeProvider";
import LanguageProvider from "./Components/providers/LanguageProvider";
import { Toaster } from "./Components/ui/Sonner";
import CopyRight from "./Components/CopyRight";

const appName = import.meta.env.VITE_APP_NAME || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.tsx`,
            import.meta.glob("./Pages/**/*.tsx")
        ),
    setup({ el, App, props }) {
        const root = createRoot(el);
        const locale = props.initialPage.props.locale as string;
        root.render(
            <LanguageProvider locale={props.initialPage.props.locale as string}>
                <ThemeProvider
                    defaultTheme="light"
                    storageKey="vite-ui-theme"
                    locale={locale}
                >
                    <App {...props} />
                    <Toaster dir={locale === "ar" ? "rtl" : "ltr"} />
                </ThemeProvider>
            </LanguageProvider>
        );
    },
    progress: {
        color: "#d60a53",
    },
});
