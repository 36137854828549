import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationAr from "./lang/ar.json";
import translationEn from "./lang/en.json";
i18n.use(initReactI18next).init({
    resources: {
        ar: {
            translation: translationAr,
        },
        en: {
            translation: translationEn,
        },
    },
    lng: "ar",
    fallbackLng: "en",
});

export default i18n;
